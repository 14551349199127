import {
  Box,
  ListItem,
  Paper,
  TextField,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { FC, useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import { useUnit } from 'effector-react';
import { useIMask } from 'react-imask';
import * as Yup from 'yup';
import { ContractAutocomplete } from '../../../../../components/ContractAutocomplete';
import { LabelForContracts } from '../../../../Campaigns/UI/LabelForContracts';
import { dataForm, dataTypes } from '../../../options';
import { SelectMS } from '../../../../../../UI/form/SelectMS';

import {
  $campaignList,
  onGetListCampaign,
  onLoadListsCampaign,
  resetCatalogsCampaign,
  searchCampaign,
} from '../model/catalogs';
import { BootstrapTooltip } from '../../../../../../components/BootstrapTooltip';
import type { FieldsCreateCreative } from '../index';
import { categories } from '../../kktuCodes';
import { getValCategories } from '../../helpers';

type Props = {
  formik: FormikProps<FieldsCreateCreative>;
  handleChange: ReturnType<
    typeof useFormik<FieldsCreateCreative>
  >['handleChange'];
};
const Parametrs: FC<Props> = ({ formik, handleChange }) => {
  const campaignList = useUnit($campaignList);
  useEffect(() => {
    onGetListCampaign();
  }, []);

  const onChangeItemUrl =
    (index: number) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const arStart = formik.values.url?.slice(0, index);
      const arEnd = formik.values.url?.slice(
        index + 1,
        formik.values.url.length,
      );
      formik
        .setFieldValue('url', [
          ...(arStart ?? []),
          e.target.value,
          ...(arEnd ?? []),
        ])
        .then(() => {
          formik.validateField('url');
        });
    };
  const onDeleteItemUrl = (index: number) => () => {
    const arStart = formik.values.url?.slice(0, index);
    const arEnd = formik.values.url?.slice(index + 1, formik.values.url.length);
    formik
      .setFieldValue('url', [...(arStart ?? []), ...(arEnd ?? [])])
      .then(() => {
        formik.validateField('url');
      });
  };

  const { ref: refValueShop, value: valValueShop } = useIMask(
    {
      mask: Number,
      scale: 0,
      thousandsSeparator: '',
      padFractionalZeros: false,
      normalizeZeros: false,
      min: 0,
      max: 32767,
      prepare(
        value: string,
        masked: unknown,
        flags: { input?: boolean; tail?: boolean },
      ) {
        if (value === '0' && !flags?.input) return '';
        return value;
      },
    },
    {
      onComplete: (value) => {
        formik.setFieldValue('shop', value, true);
      },
    },
  );

  const hideUrl = formik.values.campaign?.ord === 4 && formik.values.isNotUrl;

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '30px',
      }}
    >
      <Typography fontWeight={700} mb={3.8}>
        Параметры
      </Typography>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap="30px" mb={4}>
        <TextField
          label="Название"
          required
          variant="standard"
          value={formik.values.name}
          name="name"
          inputProps={{ maxLength: 255 }}
          autoComplete="none"
          onBlur={formik.handleBlur}
          onChange={handleChange}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.getAdditionalParamsFromCampaign}
              onChange={formik.handleChange}
              name="getAdditionalParamsFromCampaign"
            />
          }
          label="Брать ФР, тип оплаты и ККТУ из РК"
        />
        {!formik.values.getAdditionalParamsFromCampaign && (
          <>
            <SelectMS
              error={Boolean(formik.errors.type)}
              required
              label="Тип оплаты"
              value={formik.values.type || ''}
              name="type"
              onChange={handleChange}
              onBlur={formik.handleBlur}
              options={dataTypes}
              helperText={formik.errors.type}
            />

            <SelectMS
              error={Boolean(formik.errors.form)}
              required
              label="Форма распространения рекламы"
              value={formik.values.form || ''}
              name="form"
              onChange={handleChange}
              onBlur={formik.handleBlur}
              options={dataForm}
              helperText={formik.errors.form}
            />

            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Autocomplete
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={categories}
                value={
                  getValCategories(formik.values.kktuCodes, categories) ?? null
                }
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (Array.isArray(option)) {
                    return option[0];
                  }
                  return `${option.value} ${option?.label}`;
                }}
                onChange={(_, v) => {
                  const value =
                    Array.isArray(v) || typeof v === 'string' ? v : v?.value;
                  formik.setFieldValue('kktuCodes', value).then(() => {
                    formik.validateField('kktuCodes');
                  });
                }}
                onKeyDownCapture={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = options.filter((option) => {
                    if (
                      option.label
                        .toLowerCase()
                        .includes(params.inputValue.toLowerCase())
                    )
                      return true;
                    if (
                      option.value
                        .toLowerCase()
                        .includes(params.inputValue.toLowerCase())
                    )
                      return true;
                    return false;
                  });

                  const { inputValue } = params;

                  const isValid = Yup.string()
                    .matches(/^(\d{1,2}\.\d{1,2}\.\d{1,2})$/)
                    .isValidSync(inputValue);

                  if (!isValid) return filtered;

                  const isExisting = options.some(
                    (option) =>
                      inputValue ===
                      (typeof option === 'string' ? option : option.value),
                  );

                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      value: inputValue,
                      label: '',
                    });
                  }

                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Код ККТУ"
                    variant="standard"
                    error={Boolean(formik.errors.kktuCodes)}
                    helperText={formik.errors.kktuCodes}
                    required
                  />
                )}
              />
            </Box>
          </>
        )}

        <Box
          sx={{
            position: 'relative',
          }}
        >
          <ContractAutocomplete
            options={campaignList}
            value={formik.values.campaign ?? null}
            sx={{
              '.MuiInputLabel-root': { zIndex: 99 },
            }}
            label="Кампания"
            required
            placeholder="Кампания"
            onInputChange={(event, value, reason) => {
              if (reason === 'clear') {
                formik.setFieldValue('campaign', undefined);
                resetCatalogsCampaign();
              }
            }}
            onChange={(_, v) => {
              formik.setFieldValue('campaign', v).then(() => {
                formik.validateField('campaign');
              });
            }}
            onScrollEnd={onLoadListsCampaign}
            search={searchCampaign}
            getOptionLabel={(option) => `${option?.name} `}
            renderOption={(props, option) => (
              <ListItem
                {...props}
                key={option?.uuid}
                component="li"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {option?.name}
              </ListItem>
            )}
            error={Boolean(formik.errors.campaign)}
            helperText={formik.errors.campaign}
          />
        </Box>

        <TextField
          variant="standard"
          value={formik.values.creativeOkveds ?? ''}
          name="creativeOkveds"
          inputProps={{ maxLength: 255 }}
          autoComplete="none"
          onBlur={formik.handleBlur}
          onChange={handleChange}
          error={Boolean(formik.errors.creativeOkveds)}
          helperText={formik.errors.creativeOkveds}
          sx={{ '.MuiInputLabel-root': { zIndex: 99 } }}
          label={<LabelForContracts text="Коды ОКВЭД" title="Через запятую" />}
        />

        <TextField
          label="Параметры целевой аудитории рекламы"
          variant="standard"
          name="targetAudienceDescription"
          autoComplete="none"
          onBlur={formik.handleBlur}
          onChange={handleChange}
          error={Boolean(formik.errors.targetAudienceDescription)}
          helperText={formik.errors.targetAudienceDescription}
          value={formik.values.targetAudienceDescription}
        />
        <TextField
          label="Идентификатор магазина"
          variant="standard"
          name="shop"
          autoComplete="none"
          value={valValueShop}
          inputRef={refValueShop}
          error={Boolean(formik.errors.shop)}
          helperText={formik.errors.shop}
        />

        {formik.values.campaign?.ord === 4 && (
          <>
            <div />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isNotUrl}
                  onChange={formik.handleChange}
                  name="isNotUrl"
                />
              }
              label="НЕ указывать целевую ссылку"
            />
          </>
        )}

        {!hideUrl && (
          <>
            {formik.values.url?.map((item, index) => (
              <TextField
                key={index}
                label="Целевая ссылка"
                required
                variant="standard"
                name="url"
                autoComplete="none"
                onChange={onChangeItemUrl(index)}
                error={Boolean(formik.errors.url?.[index])}
                helperText={formik.errors.url?.[index]}
                value={item}
                InputProps={{
                  endAdornment:
                    index === 0 ? undefined : (
                      <BootstrapTooltip title="Удалить" placement="top">
                        <IconButton
                          color="primary"
                          aria-label="Удалить целевую ссылку"
                          size="small"
                          onClick={onDeleteItemUrl(index)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </BootstrapTooltip>
                    ),
                }}
              />
            ))}

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="end"
              mr="auto"
            >
              <BootstrapTooltip title="Добавить целевую ссылку" placement="top">
                <IconButton
                  color="primary"
                  aria-label="Добавить целевую ссылку"
                  onClick={() =>
                    formik.setFieldValue('url', [
                      ...(formik.values.url ?? []),
                      '',
                    ])
                  }
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </BootstrapTooltip>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default Parametrs;
