import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  ListItem,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useUnit } from 'effector-react';
import { NavLink, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FiExternalLink } from 'react-icons/fi';
import {
  $campaign,
  $contract,
  $iContract,
  campaignGetNoReloadFX,
  campaignUpdateFX,
  onResetBasicForm,
  onSubmitBasicForm,
  setChangedBasicForm,
  setDisabledBasicForm,
  setIsPromptBasicForm,
} from '../model';
import {
  CampaignItem,
  PCampaignUpdate,
  RCampaignGet,
} from '../../../../../../apiRPC/сampaign';
import { generateContractPath } from '../../../../../addresses';
import { isErrorProps } from '../../../../../../apiRPC/request';
import {
  $iContractList,
  onGetContracts,
  onGetIContracts,
  onLoadIContracts,
  resetCatalogs,
  searchIContract,
} from '../../Create/model';
import {
  $ordOption,
  ordGetCatalogFX,
} from '../../../../Contracts/model/catalogs';
import css from '../../styles.module.scss';
import { ContractAutocomplete } from '../../../../../components/ContractAutocomplete';
import { LabelForContracts } from '../../../UI/LabelForContracts';
import CalendarField from '../../../../../../components/CalendarField';
import { ContractItem } from '../../../../../../apiRPC/contracts';
import { SelectMS } from '../../../../../../UI/form/SelectMS';
import { dataForm, dataTypes } from '../../../../Creatives/options';
import { categories } from '../../../../Creatives/Creative/kktuCodes';
import { getValCategories } from '../../../../Creatives/Creative/helpers';

type Form = {
  name: string;
  contract: ContractItem | null;
  invoiceContract: ContractItem | null;
  dateStart: Date | null;
  dateEnd: Date | null;
  form: CampaignItem['form'];
  payType: CampaignItem['payType'];
  kktuCodes?: CampaignItem['payType'];
};
const mapName = new Map([
  ['name', 'Поле "Название"'],
  ['contract', 'Поле "Изначальный договор"'],
  ['invoiceContract', 'Поле "Договор отчета"'],
  ['dateStart', 'Поле "Дата начала"'],
  ['dateEnd', 'Поле "Дата окончания"'],
]);
function formIsChanged(formVal: Form, campaign: RCampaignGet | null): boolean {
  let res = false;
  if (!campaign) return res;

  type CheckedObj = {
    name: string;
    contract: string;
    invoiceContract: string | null;
    dateStart: number | null;
    dateEnd: number | null;
    kktuCodes?: string;
  };
  const checkedObj: CheckedObj = {
    name: formVal.name,
    contract: formVal.contract?.uuid || '',
    invoiceContract: formVal.invoiceContract?.uuid || null,
    dateStart: formVal?.dateStart ? +formVal.dateStart / 1000 : null,
    dateEnd: formVal?.dateEnd ? +formVal.dateEnd / 1000 : null,
    kktuCodes: formVal.kktuCodes,
  };

  const keys = Object.keys(checkedObj);

  for (const key of keys) {
    if (key === 'kktuCodes') {
      if (
        (checkedObj[key as keyof CheckedObj] as string) !==
        (campaign[key]?.at(0) as string)
      ) {
        res = true;
        break;
      }
      continue;
    }

    if (
      checkedObj[key as keyof CheckedObj] !==
      campaign[key as keyof RCampaignGet]
    ) {
      res = true;
      break;
    }
  }

  return res;
}

const initialValues = {
  name: '',
  contract: null,
  invoiceContract: null,
  dateStart: null,
  dateEnd: null,
  form: 'banner',
  payType: 'other',
  kktuCodes: undefined,
};
export const UpdateForm: FC = () => {
  const theme = useTheme();
  const [disableModal, setDisableModal] = useState(false);
  const campaign = useUnit($campaign);
  const { uuid } = useParams();

  const formik = useFormik<Form>({
    initialValues,
    validationSchema: Yup.object().shape(
      {
        name: Yup.string().required('Обязательное для заполнения поле'),
        contract: Yup.object()
          .shape({ ord: Yup.number().required() })
          .nullable()
          .required('Обязательное для заполнения поле')
          .when('invoiceContract', ([invoiceContract], schema) =>
            schema.test({
              test: (contract) => {
                if (!contract?.ord) return true;
                if (!invoiceContract?.ord) return true;
                return contract?.ord === invoiceContract?.ord;
              },
              message:
                'Изначальный договор и договор отчета должны пренадлежать одному ОРД',
            }),
          ),
        invoiceContract: Yup.object()
          .shape({ ord: Yup.number().required() })
          .nullable()
          .when('contract', ([contract], schema) =>
            schema.test({
              test: (invoiceContract) => {
                if (!contract?.ord) return true;
                if (!invoiceContract?.ord) return true;
                return contract?.ord === invoiceContract?.ord;
              },
              message:
                'Изначальный договор и договор отчета должны пренадлежать одному ОРД',
            }),
          ),

        dateStart: Yup.date()
          .typeError('Некорректное значение')
          .nullable()
          .required('Обязательное для заполнения поле')
          .when('dateEnd', ([dateEnd], schema) =>
            schema.test({
              test: (dateStart: Form['dateStart']) => {
                if (!dateEnd) return true;
                if (!dateStart) return true;
                return +dateStart <= +dateEnd;
              },
              message: 'Дата начала должна быть меньше даты окончания',
            }),
          ),
        dateEnd: Yup.date()
          .typeError('Некорректное значение')
          .nullable()
          .required('Обязательное для заполнения поле')
          .when('dateStart', ([dateStart], schema) =>
            schema.test({
              test: (dateEnd: Form['dateEnd']) =>
                !dateEnd || !dateStart || +dateEnd >= +dateStart,
              message: 'Дата начала должна быть меньше даты окончания',
            }),
          ),
        form: Yup.string().required('Обязательное для заполнения поле'),
        payType: Yup.string().required('Обязательное для заполнения поле'),
        kktuCodes: Yup.string().matches(
          /^(\d{1,2}\.\d{1,2}\.\d{1,2})$/,
          'Невалидное значение',
        ),
      },
      [
        ['contract', 'invoiceContract'],
        ['dateStart', 'dateEnd'],
      ],
    ),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      const formChanged = formIsChanged(formik.values, campaign);
      if (!formChanged) return;
      try {
        setDisableModal(true);

        const fields: PCampaignUpdate['fields'] = {
          name: values.name,
          invoiceContract: values.invoiceContract?.uuid || undefined,
          dateStart: +values.dateStart! / 1000,
          dateEnd: +values.dateEnd! / 1000,
          kktuCodes: values.kktuCodes,
        };

        await campaignUpdateFX({ uuid: uuid!, fields });
        // f.resetForm();
        campaignGetNoReloadFX({ uuid: uuid! });
        toast.success('Кампания успешно изменена', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        // navigateForEffect(
        //   `${addressesORD.campaignsPath}?status=${campaign?.status}`,
        // );
      } catch (e) {
        setDisableModal(false);
        console.error(e);

        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            let text = field.description;
            Array.from(mapName).forEach(([key, val]) => {
              text = text.replace(key, val);
            });
            f.setFieldError(field.field, text);
          });
        }
      }
    },
  });

  const contract = useUnit($contract);
  const iContract = useUnit($iContract);
  useEffect(() => {
    onGetContracts();
    onGetIContracts();
    ordGetCatalogFX(undefined);
    return resetCatalogs;
  }, []);

  useEffect(() => {
    const stop = onResetBasicForm.watch(() => {
      if (!campaign) return;
      formik
        .setValues({
          name: campaign.name,
          contract: contract!,
          invoiceContract: iContract,
          dateStart: new Date(campaign.dateStart * 1000),
          dateEnd: new Date(campaign.dateEnd * 1000),
          form: campaign.form,
          payType: campaign.payType,
          kktuCodes: campaign?.kktuCodes?.[0],
        })
        .then(() => {
          formik.validateForm();
        });
    });
    return stop;
  }, [campaign, formik]);
  useEffect(() => {
    const stop = onSubmitBasicForm.watch(() => {
      formik.submitForm();
    });
    return stop;
  }, [formik]);

  useEffect(() => {
    if (!campaign) return;

    formik.setValues({
      name: campaign.name,
      contract: formik.values.contract,
      invoiceContract: formik.values.invoiceContract,
      dateStart: new Date(campaign.dateStart * 1000),
      dateEnd: new Date(campaign.dateEnd * 1000),
      form: campaign.form,
      payType: campaign.payType,
      kktuCodes: campaign?.kktuCodes?.[0],
    });
  }, [campaign]);

  useEffect(() => {
    if (!contract) return;
    formik.setFieldValue('contract', contract);
  }, [contract]);
  useEffect(() => {
    if (!iContract) return;
    formik.setFieldValue('invoiceContract', iContract);
  }, [iContract]);

  const handleChange: (typeof formik)['handleChange'] = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      formik.setFieldValue(name, value).then(() => {
        formik.validateField(name);
      });
    },
    [formik],
  );

  const iContractList = useUnit($iContractList);
  const ordOption = useUnit($ordOption);

  const getOrdName = (id: number) => {
    const find = ordOption.find((item) => item.value === id);
    return find?.label ?? id;
  };

  const formChanged = formIsChanged(formik.values, campaign);

  const btnSendDisabled = !formik.isValid;
  const isPrompt = disableModal ? false : formChanged;

  useEffect(() => {
    setDisabledBasicForm(btnSendDisabled);
  }, [btnSendDisabled]);

  useEffect(() => {
    setChangedBasicForm(formChanged);
  }, [formChanged]);

  useEffect(() => {
    setIsPromptBasicForm(isPrompt);
  }, [isPrompt]);

  const isChangedInvoiceContract = Boolean(campaign?.invoiceContract);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '30px',
      }}
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Typography fontWeight={700} mb={3.8}>
        Параметры
      </Typography>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap="30px" mb={4}>
        <TextField
          label="Название"
          required
          variant="standard"
          value={formik.values.name}
          name="name"
          onChange={handleChange}
          helperText={formik.errors.name || ''}
          error={Boolean(formik.errors.name)}
          inputProps={{ maxLength: 255 }}
          autoComplete="none"
        />
        <Box
          sx={{
            position: 'relative',
            display: 'grid',
          }}
        >
          <TextField
            disabled
            label="Изначальный договор"
            variant="standard"
            value={`${formik.values.contract?.number} • ${
              formik.values.contract?.ord
                ? getOrdName(formik.values.contract.ord)
                : formik.values.contract?.ord
            } • ${formik.values.contract?.customerInn}`}
            name="contract"
            autoComplete="none"
            sx={{
              input: {
                paddingRight: '40px',
              },
            }}
          />
          <NavLink
            to={generateContractPath(formik.values.contract?.uuid || '')}
            target="_blank"
          >
            <FiExternalLink
              size={20}
              color={theme.palette.primary.main}
              className={css.link}
            />
          </NavLink>
        </Box>

        {!isChangedInvoiceContract ? (
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <ContractAutocomplete
              options={iContractList}
              value={formik.values.invoiceContract}
              sx={{
                '.MuiInputLabel-root': { zIndex: 99 },
              }}
              label={
                <LabelForContracts
                  text="Договор отчета"
                  title="№ Договора, ОРД, ИНН"
                />
              }
              placeholder="Название / ИНН"
              onChange={(_, v) => {
                formik.setFieldValue('invoiceContract', v).then(() => {
                  formik.validateField('invoiceContract');
                  formik.validateField('contract');
                });
              }}
              error={Boolean(formik.errors.invoiceContract)}
              helperText={formik.errors.invoiceContract}
              onScrollEnd={onLoadIContracts}
              search={searchIContract}
              getOptionLabel={(option) =>
                `${option?.number} • ${
                  option?.ord ? getOrdName(option.ord) : option?.ord
                } • ${option?.customerInn}`
              }
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  key={option?.uuid}
                  component="li"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {option?.number} •{' '}
                  {option?.ord ? getOrdName(option.ord) : option?.ord} •{' '}
                  {option?.customerInn}
                </ListItem>
              )}
            />
          </Box>
        ) : (
          <Box
            sx={{
              position: 'relative',
              display: 'grid',
            }}
          >
            <TextField
              disabled
              label="Договор отчета"
              variant="standard"
              value={`${formik.values.invoiceContract?.number} • ${
                formik.values.invoiceContract?.ord
                  ? getOrdName(formik.values.invoiceContract.ord)
                  : formik.values.invoiceContract?.ord
              } • ${formik.values.invoiceContract?.customerInn}`}
              name="invoiceContract"
              autoComplete="none"
              sx={{
                input: {
                  paddingRight: '40px',
                },
              }}
            />
            <NavLink
              to={generateContractPath(
                formik.values.invoiceContract?.uuid || '',
              )}
              target="_blank"
            >
              <FiExternalLink
                size={20}
                color={theme.palette.primary.main}
                className={css.link}
              />
            </NavLink>
          </Box>
        )}

        <CalendarField
          required
          label="Дата начала"
          views={['month', 'year']}
          inputFormat="MM.yyyy"
          placeholder="ММ.ГГГГ"
          disableMaskedInput={false}
          value={formik.values.dateStart}
          onChange={(v) => {
            formik.setFieldValue('dateStart', v).then(() => {
              formik.validateField('dateStart');
              formik.validateField('dateEnd');
            });
          }}
          error={formik.errors.dateStart}
          helperText={formik.errors.dateStart}
          onClear={() =>
            formik.setFieldValue('dateStart', null).then(() => {
              formik.validateField('dateStart');
              formik.validateField('dateEnd');
            })
          }
        />
        <CalendarField
          required
          label="Дата окончания"
          views={['month', 'year']}
          inputFormat="MM.yyyy"
          placeholder="ММ.ГГГГ"
          disableMaskedInput={false}
          value={formik.values.dateEnd}
          onChange={(v) => {
            formik.setFieldValue('dateEnd', v).then(() => {
              formik.validateField('dateEnd');
              formik.validateField('dateStart');
            });
          }}
          error={formik.errors.dateEnd}
          helperText={formik.errors.dateEnd}
          onClear={() =>
            formik.setFieldValue('dateEnd', null).then(() => {
              formik.validateField('dateStart');
              formik.validateField('dateEnd');
            })
          }
        />

        <SelectMS
          error={Boolean(formik.errors.form)}
          required
          label="Форма распространения рекламы"
          value={formik.values.form || ''}
          name="form"
          disabled
          options={dataForm}
          helperText={formik.errors.form}
        />

        <SelectMS
          error={Boolean(formik.errors.payType)}
          required
          label="Тип оплаты"
          value={formik.values.payType || ''}
          name="payType"
          disabled
          options={dataTypes}
          helperText={formik.errors.payType}
        />

        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Autocomplete
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={categories}
            disabled={Boolean(campaign?.kktuCodes?.length)}
            value={
              getValCategories(formik.values.kktuCodes, categories) ?? null
            }
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (Array.isArray(option)) {
                return option[0];
              }
              return `${option.value} ${option?.label}`;
            }}
            onChange={(_, v) => {
              const value =
                Array.isArray(v) || typeof v === 'string' ? v : v?.value;
              formik.setFieldValue('kktuCodes', value).then(() => {
                formik.validateField('kktuCodes');
              });
            }}
            onKeyDownCapture={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            filterOptions={(options, params) => {
              const filtered = options.filter((option) => {
                if (
                  option.label
                    .toLowerCase()
                    .includes(params.inputValue.toLowerCase())
                )
                  return true;
                if (
                  option.value
                    .toLowerCase()
                    .includes(params.inputValue.toLowerCase())
                )
                  return true;
                return false;
              });

              const { inputValue } = params;

              const isValid = Yup.string()
                .matches(/^(\d{1,2}\.\d{1,2}\.\d{1,2})$/)
                .isValidSync(inputValue);

              if (!isValid) return filtered;

              const isExisting = options.some(
                (option) =>
                  inputValue ===
                  (typeof option === 'string' ? option : option.value),
              );

              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  value: inputValue,
                  label: '',
                });
              }

              return filtered;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Код ККТУ"
                variant="standard"
                error={Boolean(formik.errors.kktuCodes)}
                helperText={formik.errors.kktuCodes}
              />
            )}
          />
        </Box>
      </Box>
    </Paper>
  );
};
