import React, {
  DragEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  useTransition,
} from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Backdrop,
  Typography,
  useTheme,
  ThemeProvider,
  Tabs,
  Tab,
  createTheme,
} from '@mui/material';
import { useUnit } from 'effector-react';
import CircularProgress from '@mui/material/CircularProgress';
import { generatePath, useMatch, useParams } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { Title } from './Title';
import { Description } from './Description';

import { $card, cardGetPreloaderFX, resetCard } from '../../../../model/card';
import { $column, resetColumn } from '../../../../model/column';
import { Marks } from './Marks';
import { RightMenu } from './RiggtMenu';

import { ContainerMarksMenu } from '../../Card/Marks/ContanerMarksMenu';
import { Members } from './Members';
import { MenuMembers } from '../../Card/Members/MenuMembers';
import { useMarks } from './Marks/useMarks';
import { useMembers } from './Members/useMembers';
import { useFileUpload } from '../../../../../helper/useFileUpload/useFileUpload';
import { Attachments } from './Attachments';
import { MenuAtachments } from './Attachments/MenuAtacHments';
import { Comments } from './Comments';
import {
  $historyCommentTab,
  $OpenTaskOverview,
  resetOpenTaskOverview,
  seTHistoryCommentTab,
} from '../../../../model/taskOverview';
import { StatusTitle } from './StatysTitle';
import { navigateForEffect } from '../../../../../model/routing';
import { makeSlug } from '../../../../../helper/makeSlug';
import { Dates } from './Dates';
import { addressesGRID, cardPath } from '../../../../addresses';
import { useIsMobile } from '../../../../../helper/hooks/isMobile';
import { throttle } from '../../../../../helper/throttle';
import { themeGrid } from '../../../../../theme/themeGrid';
import { History } from './History';
import {
  cardGetCommentsFX,
  commentsReset,
} from '../../../../model/card/comment';

const themeG = createTheme(themeGrid);

const styleWrapper = {
  display: 'grid',
  gridTemplateColumns: 'calc(100% - 200px)  200px',
  gap: 1,
  wordBreak: 'break-word',
  '@media screen and (max-width: 1024px)': {
    gridTemplateColumns: 'minmax(100%, calc(100% - 200px))  200px',
  },
};
export const TaskOverview: FC = () => {
  const isMobile = useIsMobile();
  const card = useUnit($card);
  const column = useUnit($column);
  const pendingCard = useUnit(cardGetPreloaderFX.pending);
  const historyCommentTab = useUnit($historyCommentTab);
  const { cardId, cName } = useParams();

  const onClose = useCallback(() => {
    resetCard();
    resetColumn();
    resetOpenTaskOverview();
    navigateForEffect(
      generatePath(addressesGRID.deskPath, { uuid: card?.board }),
    );
  }, [card]);

  const anchorEl = useRef<HTMLDivElement>(null);

  const isOpenDialog = useUnit($OpenTaskOverview);

  const isCard = useMatch(`/${cardPath}/*`);

  const [_, startTransition] = useTransition();
  useEffect(() => {
    if (!isCard) return;
    if (!card?.title) return;
    const currentTitle = makeSlug(card?.title);
    if (cName === currentTitle) return;

    startTransition(() =>
      navigateForEffect(
        generatePath(addressesGRID.cardPathWithName, {
          cardId,
          dName: currentTitle,
        }),
        {
          replace: true,
        },
      ),
    );
  }, [card?.title, cardId]);

  const {
    showMembers,
    listMembersForCard,
    onOpenMenuMembers,
    openMenuMembers,
    accounts,
    ListUuidMembersForCard,
    onClickMember,
    onCloseMenuMembers,
  } = useMembers({ isOpenDialog });

  const theme = useTheme();

  const {
    showMarks,
    listMarksForCard,
    onOpenMenuMarks,
    openMenuMarks,
    setOpenMenuMarks,
    ListUuidMarkForCard,
    onClickMark,
    updateMarksForCard,
  } = useMarks({ isOpenDialog, board: card?.board || '' });

  const pending = pendingCard && !isOpenDialog;

  const { files, handleDragDropEvent, setFiles, eventType } = useFileUpload();
  const showAttachments =
    Boolean(card?.attachments?.length) || Boolean(files.size);

  const [openMenuAttachments, setOpenMenuAttachments] = useState(false);

  const handleOpenMenuAttachments = () => setOpenMenuAttachments(true);

  const showDates = card?.dates?.dateStart || card?.dates?.dateEnd;

  useEffect(() => {
    if (!card?.uuid) return undefined;
    cardGetCommentsFX({ card: card.uuid });
    return () => commentsReset();
  }, [card?.uuid]);

  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      if (!e.clipboardData) return;
      if (!card?.uuid) return;

      const { target } = e;
      if (!(target instanceof Element)) return;
      const isInsideCard = target.closest('#cardContainer');
      if (!isInsideCard) return;

      if (
        target instanceof Element &&
        target.classList.contains('CommentContent')
      ) {
        const commentUid = target.getAttribute('data-comment_id');
        if (e.clipboardData.files.length) {
          e.preventDefault();
        }
        setFiles({ cardId: card?.uuid || '', commentUid: commentUid || '' })(
          Array.from(e.clipboardData.files),
        );
        return;
      }
      setFiles({ cardId: card?.uuid || '' })(Array.from(e.clipboardData.files));
    };

    const handlePasteThrottle = throttle(handlePaste, 1000);
    document.body.addEventListener('paste', handlePasteThrottle);
    return () => {
      document.body.removeEventListener('paste', handlePasteThrottle);
      seTHistoryCommentTab('COMMENT');
    };
  }, [card?.uuid]);

  return (
    <>
      <Backdrop
        sx={{
          color: theme.palette.info.light,
          zIndex: (t) => t.zIndex.drawer + 1,
        }}
        open={pending}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        fullWidth
        fullScreen={isMobile}
        disableEscapeKeyDown
        maxWidth="xl"
        open={isOpenDialog}
        onClose={onClose}
        transitionDuration={0}
      >
        <StatusTitle onClose={onClose} status={card?.status || 'ACTIVE'} />

        <DialogContent id="cardContainer">
          <Box
            onDragEnter={handleDragDropEvent}
            onDragOver={handleDragDropEvent}
            onDrop={(e: DragEvent<HTMLDivElement>) => {
              handleDragDropEvent(e);
              setFiles({ cardId: card?.uuid || '' })(e);
            }}
            onDragEnd={handleDragDropEvent}
            onDragLeave={handleDragDropEvent}
            component="div"
          >
            <Title
              title={card?.title || ''}
              uuid={card?.uuid || ''}
              columnTitle={column?.title}
            />

            <Box sx={styleWrapper}>
              <Box>
                {showDates && (
                  <Box mt={1} ml={4}>
                    <Dates uuid={card?.uuid || ''} />
                  </Box>
                )}
                {showMembers && (
                  <Box mt={1} ml={4}>
                    <Members
                      members={listMembersForCard || []}
                      onAdd={onOpenMenuMembers}
                    />
                  </Box>
                )}

                {showMarks && (
                  <Box mt={1} ml={4}>
                    <Marks
                      marks={listMarksForCard}
                      onClickButton={onOpenMenuMarks}
                    />
                  </Box>
                )}

                <Box mt={2}>
                  <Description
                    description={card?.description || ''}
                    uuid={card?.uuid || ''}
                  />
                </Box>
                {showAttachments && (
                  <Box mt={2}>
                    <Attachments
                      uploadFiles={files}
                      cardId={card?.uuid || ''}
                      attachments={
                        card?.attachments?.filter((item) => !item.deleted) || []
                      }
                      archives={
                        card?.attachments
                          ?.filter((item) => item.deleted)
                          .sort((a, b) => b.createdAt - a.createdAt) || []
                      }
                    />
                  </Box>
                )}

                {card?.uuid && (
                  <TabContext value={historyCommentTab}>
                    <Box mt={2}>
                      <ThemeProvider theme={themeG}>
                        <Tabs
                          onChange={(e, v) => seTHistoryCommentTab(v)}
                          value={historyCommentTab}
                        >
                          <Tab label="КОММЕНТАРИИ" value="COMMENT" />
                          <Tab label="ИСТОРИЯ" value="HISTORY" />
                        </Tabs>
                      </ThemeProvider>
                    </Box>
                    <Box mt={2}>
                      <TabPanel value="COMMENT">
                        <Comments uuid={card?.uuid} />
                      </TabPanel>

                      <TabPanel value="HISTORY">
                        <History uuid={card?.uuid} />
                      </TabPanel>
                    </Box>
                  </TabContext>
                )}
              </Box>

              <Box>
                <div ref={anchorEl} />
                <RightMenu
                  onClickMark={onOpenMenuMarks}
                  onClickMembers={onOpenMenuMembers}
                  onClickAttachments={handleOpenMenuAttachments}
                  uuid={cardId || ''}
                  status={card?.status}
                  onCloseModal={onClose}
                  countCreatives={card?.countCreatives || 0}
                />
              </Box>
            </Box>

            {!['dragleave', 'drop', 'dragend', ''].includes(eventType) && (
              <Box
                position="absolute"
                sx={{ background: 'rgba(255, 255, 255, 0.7)' }}
                top="0"
                left="0"
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                onDragLeave={handleDragDropEvent}
              >
                <Typography
                  variant="h5"
                  fontWeight={600}
                  color="rgb(102 102 102)"
                  align="center"
                  component="div"
                  sx={{ pointerEvents: 'none' }}
                >
                  Перетяните файлы чтобы загрузить
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {card?.board && (
        <>
          <ContainerMarksMenu
            anchorEl={anchorEl?.current}
            board={card?.board}
            openMenuMarks={openMenuMarks}
            setOpenMenuMarks={setOpenMenuMarks}
            listChecked={ListUuidMarkForCard}
            onClickMark={onClickMark}
            handleClose={() => updateMarksForCard()}
          />

          <MenuMembers
            openMenu={openMenuMembers}
            anchorEl={anchorEl?.current}
            handleClose={onCloseMenuMembers}
            accounts={accounts}
            listChecked={ListUuidMembersForCard}
            onClickMember={onClickMember}
          />

          <MenuAtachments
            open={openMenuAttachments}
            anchorEl={anchorEl?.current}
            onClose={() => setOpenMenuAttachments(false)}
            cardId={card?.uuid || ''}
          />
        </>
      )}
    </>
  );
};
