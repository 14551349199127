import { AxiosResponse } from 'axios';
import request, { ResulType } from '../request';

export type ErirStatus = 'NONE' | 'PENDING' | 'SUCCESS' | 'ERROR';
export type Status = 'success' | 'pending' | 'error' | 'on_approval';
export type Filter = {
  limit: number;
  offset: number;
  loadingStatus?: string | string[];
  ord?: number;
  campaign?: string;
  erirStatus?: ErirStatus;
  status?: Status;
  search?: string;
};
export type PCreativeGetList = {
  filter: Filter;
};

type LoadingStatus =
  | 'NONE'
  | 'PENDING'
  | 'PENDING_RESOURCE'
  | 'SUCCESS'
  | 'ERROR'
  | 'ON_DEV'
  | 'ON_APPROVAL';

type Campaign = { uuid: string; name: string };
type Type = 'cpa' | 'cpc' | 'cpm' | 'other';
type Form =
  | 'banner'
  | 'text-block'
  | 'text-graphic-block'
  | 'video'
  | 'audio-rec'
  | 'live-audio'
  | 'live-video'
  | 'other';
export type CreativeItem = {
  uuid: string;
  externalId: string | null;
  name: string;
  type: Type;
  form: Form;
  erid: string;
  ord: number;
  loadingStatus: LoadingStatus;
  erirStatus: ErirStatus;
  campaign: Campaign;
};
export type RCreativeGetList = { total: number; rows: CreativeItem[] };
export const creativeGetList = request<RCreativeGetList, PCreativeGetList>({
  method: 'creative.getList',
});

export type Creative = {
  uuid: string;
  name: string;
  campaign: string;
  creativeOkveds: string[];
  description: string;
  type: Type;
  form: Form;
  targetAudienceDescription: string;
  url: string[];
  html: string;
  defaultResourceDescription: string;
  externalId: string | null;
  erid: string;
  loadingStatus: LoadingStatus;
  loadingAt: number | null;
  loadingError: string;
  erirStatus: ErirStatus;
  erirExportedOn: number | null;
  erirPlannedExportDate: number | null;
  ord: number;
  shop: number | null;
  canDelete: boolean;
  kktuCodes?: string[] | string;
  getAdditionalParamsFromCampaign?: boolean;
};

export type PCreativeCreate = {
  fields: {
    name: Creative['name'];
    campaign: Creative['campaign'];
    creativeOkveds?: Creative['creativeOkveds'];
    description: Creative['description'];
    type?: Creative['type'];
    form?: Creative['form'];
    targetAudienceDescription?: Creative['targetAudienceDescription'];
    url?: Creative['url'];
    html?: Creative['html'];
    defaultResourceDescription?: Creative['defaultResourceDescription'];
    shop?: Creative['shop'];
    kktuCodes?: Creative['kktuCodes'];
    getAdditionalParamsFromCampaign?: boolean;
  };
};
export type RCreativeCreate = Creative;
export const creativeCreate = request<RCreativeCreate, PCreativeCreate>({
  method: 'creative.create',
});
export const creativesCreate = request<
  ResulType<RCreativeCreate>[],
  PCreativeCreate[]
>({
  method: 'creative.create',
  multi: true,
});

export type PCreativeGet = { uuid: string };
export type RCreativeGet = Creative;
export const creativeGet = request<RCreativeGet, PCreativeGet>({
  method: 'creative.get',
});
export const creativesGet = request<ResulType<RCreativeGet>[], PCreativeGet[]>({
  method: 'creative.get',
  multi: true,
});

export type PCreativeUpdate = {
  uuid: string;
  fields: Partial<
    Pick<
      Creative,
      | 'name'
      | 'campaign'
      | 'creativeOkveds'
      | 'description'
      | 'type'
      | 'form'
      | 'targetAudienceDescription'
      | 'url'
      | 'html'
      | 'defaultResourceDescription'
      | 'shop'
      | 'kktuCodes'
      | 'getAdditionalParamsFromCampaign'
    >
  >;
};

export type RCreativeUpdate = Creative;
export const creativeUpdate = request<RCreativeUpdate, PCreativeUpdate>({
  method: 'creative.update',
});
