import {
  Autocomplete,
  Box,
  Button,
  Container,
  ListItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUnit } from 'effector-react';
import { toast } from 'react-toastify';
import deepEquals from 'deep-equal';
import ReactRouterPrompt from 'react-router-prompt';
import Header from '../../../../components/Header';
import { addressesORD } from '../../../../addresses';

import CalendarField from '../../../../../components/CalendarField';
import {
  $contractList,
  $iContractList,
  campaignCreateFX,
  onGetContracts,
  onGetIContracts,
  onLoadContracts,
  onLoadIContracts,
  resetCatalogs,
  searchContract,
  searchIContract,
} from './model';
import { ContractItem } from '../../../../../apiRPC/contracts';
import { ContractAutocomplete } from '../../../../components/ContractAutocomplete';
import { $ordOption, ordGetCatalogFX } from '../../../Contracts/model/catalogs';
import { CampaignItem, PCampaignCreate } from '../../../../../apiRPC/сampaign';
import { navigateForEffect } from '../../../../../model/routing';
import Modal from '../../../../../components/Modal';
import { isErrorProps } from '../../../../../apiRPC/request';
import { LabelForContracts } from '../../UI/LabelForContracts';
import { SelectMS } from '../../../../../UI/form/SelectMS';
import { dataForm, dataTypes } from '../../../Creatives/options';
import { categories } from '../../../Creatives/Creative/kktuCodes';
import { getValCategories } from '../../../Creatives/Creative/helpers';

type Form = {
  name: string;
  contract: ContractItem | null;
  invoiceContract: ContractItem | null;
  dateStart: Date | null;
  dateEnd: Date | null;
  form: CampaignItem['form'];
  payType: CampaignItem['payType'];
  kktuCodes?: CampaignItem['payType'];
};

const mapName = new Map([
  ['name', 'Поле "Название"'],
  ['contract', 'Поле "Изначальный договор"'],
  ['invoiceContract', 'Поле "Договор отчета"'],
  ['dateStart', 'Поле "Дата начала"'],
  ['dateEnd', 'Поле "Дата окончания"'],
]);
const initialValues = {
  name: '',
  contract: null,
  invoiceContract: null,
  dateStart: null,
  dateEnd: null,
  form: 'banner',
  payType: 'other',
  kktuCodes: undefined,
};
export const Create: FC = () => {
  const [disableModal, setDisableModal] = useState(false);
  const formik = useFormik<Form>({
    initialValues,
    validationSchema: Yup.object().shape(
      {
        name: Yup.string().required('Обязательное для заполнения поле'),
        contract: Yup.object()
          .shape({ ord: Yup.number().required() })
          .nullable()
          .required('Обязательное для заполнения поле')
          .when('invoiceContract', ([invoiceContract], schema) =>
            schema.test({
              test: (contract) => {
                if (!contract?.ord) return true;
                if (!invoiceContract?.ord) return true;
                return contract?.ord === invoiceContract?.ord;
              },
              message:
                'Изначальный договор и договор отчета должны пренадлежать одному ОРД',
            }),
          ),
        invoiceContract: Yup.object()
          .shape({ ord: Yup.number().required() })
          .nullable()
          .when('contract', ([contract], schema) =>
            schema.test({
              test: (invoiceContract) => {
                if (!contract?.ord) return true;
                if (!invoiceContract?.ord) return true;
                return contract?.ord === invoiceContract?.ord;
              },
              message:
                'Изначальный договор и договор отчета должны пренадлежать одному ОРД',
            }),
          ),

        dateStart: Yup.date()
          .typeError('Некорректное значение')
          .nullable()
          .required('Обязательное для заполнения поле')
          .when('dateEnd', ([dateEnd], schema) =>
            schema.test({
              test: (dateStart: Form['dateStart']) =>
                !dateEnd || !dateStart || +dateEnd >= +dateStart,
              message: 'Дата начала должна быть меньше даты окончания',
            }),
          ),
        dateEnd: Yup.date()
          .typeError('Некорректное значение')
          .nullable()
          .required('Обязательное для заполнения поле')
          .when('dateStart', ([dateStart], schema) =>
            schema.test({
              test: (dateEnd: Form['dateEnd']) =>
                !dateEnd || !dateStart || +dateEnd >= +dateStart,
              message: 'Дата начала должна быть меньше даты окончания',
            }),
          ),
        form: Yup.string().required('Обязательное для заполнения поле'),
        payType: Yup.string().required('Обязательное для заполнения поле'),
        kktuCodes: Yup.string().matches(
          /^(\d{1,2}\.\d{1,2}\.\d{1,2})$/,
          'Невалидное значение',
        ),
      },
      [
        ['contract', 'invoiceContract'],
        ['dateStart', 'dateEnd'],
      ],
    ),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values, f) => {
      try {
        setDisableModal(true);

        const fields: PCampaignCreate['fields'] = {
          name: values.name,
          contract: values.contract?.uuid || '',
          invoiceContract: values.invoiceContract?.uuid,
          dateStart: +values.dateStart! / 1000,
          dateEnd: +values.dateEnd! / 1000,
          payType: values.payType,
          form: values.form,
          kktuCodes: values.kktuCodes ? values.kktuCodes : undefined,
        };

        await campaignCreateFX({ fields });
        f.resetForm();
        toast.success('Кампания успешно создана ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigateForEffect(addressesORD.campaignsPath);
      } catch (e) {
        setDisableModal(false);
        console.error(e);

        if (isErrorProps(e)) {
          e?.data?.fields?.forEach((field) => {
            let text = field.description;
            Array.from(mapName).forEach(([key, val]) => {
              text = text.replace(key, val);
            });
            f.setFieldError(field.field, text);
          });
        }
      }
    },
  });

  const handleChange: (typeof formik)['handleChange'] = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      formik.setFieldValue(name, value).then(() => {
        formik.validateField(name);
      });
    },
    [formik],
  );
  useEffect(() => {
    onGetContracts();
    onGetIContracts();
    ordGetCatalogFX(undefined);
    return resetCatalogs;
  }, []);

  const contractList = useUnit($contractList);
  const iContractList = useUnit($iContractList);
  const ordOption = useUnit($ordOption);
  const pending = useUnit(campaignCreateFX.pending);

  const isPrompt = disableModal
    ? false
    : !deepEquals(formik.values, initialValues);

  const getOrdName = (id: number) => {
    const find = ordOption.find((item) => item.value === id);
    return find?.label ?? id;
  };

  return (
    <Container maxWidth={false} sx={{ width: '100%', minWidth: 'auto' }}>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        height="calc(100vh - 108px )"
      >
        <Box component="form" noValidate>
          <Header>
            <Box display="flex" alignItems="center">
              <Typography
                variant="h1"
                sx={{
                  marginLeft: '2px',
                }}
              >
                Новая кампания
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <NavLink to={addressesORD.campaignsPath}>
                <Button
                  variant="outlined"
                  sx={{
                    marginLeft: '36px',
                  }}
                  disabled={pending}
                >
                  Отмена
                </Button>
              </NavLink>
              <LoadingButton
                onClick={formik.submitForm}
                loading={pending}
                variant="contained"
                sx={{
                  marginLeft: '36px',
                }}
              >
                Сохранить
              </LoadingButton>
            </Box>
          </Header>
          <Box>
            <Paper
              elevation={0}
              sx={{
                padding: '30px',
              }}
            >
              <Typography fontWeight={700} mb={3.8}>
                Параметры
              </Typography>
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr"
                gap="30px"
                mb={4}
              >
                <TextField
                  label="Название"
                  required
                  variant="standard"
                  value={formik.values.name}
                  name="name"
                  onChange={handleChange}
                  helperText={formik.errors.name || ''}
                  error={Boolean(formik.errors.name)}
                  inputProps={{ maxLength: 255 }}
                  autoComplete="none"
                />
                <Box
                  sx={{
                    position: 'relative',
                  }}
                >
                  <ContractAutocomplete
                    options={contractList}
                    value={formik.values.contract}
                    sx={{
                      '.MuiInputLabel-root': { zIndex: 99 },
                    }}
                    label={
                      <LabelForContracts
                        text="Изначальный договор"
                        title="№ Договора, ОРД, ИНН"
                        required
                      />
                    }
                    placeholder="Название / ИНН"
                    onChange={(_, v) => {
                      formik.setFieldValue('contract', v).then(() => {
                        formik.validateField('contract');
                        formik.validateField('invoiceContract');
                      });
                    }}
                    error={Boolean(formik.errors.contract)}
                    helperText={formik.errors.contract}
                    onScrollEnd={onLoadContracts}
                    search={searchContract}
                    getOptionLabel={(option) =>
                      `${option?.number} • ${
                        option?.ord ? getOrdName(option.ord) : option?.ord
                      } • ${option?.customerInn}`
                    }
                    renderOption={(props, option) => (
                      <ListItem
                        {...props}
                        key={option?.uuid}
                        component="li"
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {option?.number} •{' '}
                        {option?.ord ? getOrdName(option.ord) : option?.ord} •{' '}
                        {option?.customerInn}
                      </ListItem>
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    position: 'relative',
                  }}
                >
                  <ContractAutocomplete
                    options={iContractList}
                    value={formik.values.invoiceContract}
                    sx={{
                      '.MuiInputLabel-root': { zIndex: 99 },
                    }}
                    label={
                      <LabelForContracts
                        text="Договор отчета"
                        title="№ Договора, ОРД, ИНН"
                      />
                    }
                    placeholder="Название / ИНН"
                    onChange={(_, v) => {
                      formik.setFieldValue('invoiceContract', v).then(() => {
                        formik.validateField('invoiceContract');
                        formik.validateField('contract');
                      });
                    }}
                    error={Boolean(formik.errors.invoiceContract)}
                    helperText={formik.errors.invoiceContract}
                    onScrollEnd={onLoadIContracts}
                    search={searchIContract}
                    getOptionLabel={(option) =>
                      `${option?.number} • ${
                        option?.ord ? getOrdName(option.ord) : option?.ord
                      } • ${option?.customerInn}`
                    }
                    renderOption={(props, option) => (
                      <ListItem
                        {...props}
                        key={option?.uuid}
                        component="li"
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {option?.number} •{' '}
                        {option?.ord ? getOrdName(option.ord) : option?.ord} •{' '}
                        {option?.customerInn}
                      </ListItem>
                    )}
                  />
                </Box>
                <CalendarField
                  required
                  label="Дата начала"
                  views={['month', 'year']}
                  inputFormat="MM.yyyy"
                  placeholder="ММ.ГГГГ"
                  disableMaskedInput={false}
                  value={formik.values.dateStart}
                  onChange={(v) => {
                    formik.setFieldValue('dateStart', v).then(() => {
                      formik.validateField('dateStart');
                      formik.validateField('dateEnd');
                    });
                  }}
                  error={formik.errors.dateStart}
                  helperText={formik.errors.dateStart}
                  onClear={() =>
                    formik.setFieldValue('dateStart', null).then(() => {
                      formik.validateField('dateStart');
                      formik.validateField('dateEnd');
                    })
                  }
                />
                <CalendarField
                  required
                  label="Дата окончания"
                  views={['month', 'year']}
                  inputFormat="MM.yyyy"
                  placeholder="ММ.ГГГГ"
                  disableMaskedInput={false}
                  value={formik.values.dateEnd}
                  onChange={(v) => {
                    formik.setFieldValue('dateEnd', v).then(() => {
                      formik.validateField('dateEnd');
                      formik.validateField('dateStart');
                    });
                  }}
                  error={formik.errors.dateEnd}
                  helperText={formik.errors.dateEnd}
                  onClear={() =>
                    formik.setFieldValue('dateEnd', null).then(() => {
                      formik.validateField('dateStart');
                      formik.validateField('dateEnd');
                    })
                  }
                />

                <SelectMS
                  error={Boolean(formik.errors.form)}
                  required
                  label="Форма распространения рекламы"
                  value={formik.values.form || ''}
                  name="form"
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  options={dataForm}
                  helperText={formik.errors.form}
                />

                <SelectMS
                  error={Boolean(formik.errors.payType)}
                  required
                  label="Тип оплаты"
                  value={formik.values.payType || ''}
                  name="payType"
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  options={dataTypes}
                  helperText={formik.errors.payType}
                />

                <Box
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Autocomplete
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={categories}
                    value={
                      getValCategories(formik.values.kktuCodes, categories) ??
                      null
                    }
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (Array.isArray(option)) {
                        return option[0];
                      }
                      return `${option.value} ${option?.label}`;
                    }}
                    onChange={(_, v) => {
                      const value =
                        Array.isArray(v) || typeof v === 'string'
                          ? v
                          : v?.value;
                      formik.setFieldValue('kktuCodes', value).then(() => {
                        formik.validateField('kktuCodes');
                      });
                    }}
                    onKeyDownCapture={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = options.filter((option) => {
                        if (
                          option.label
                            .toLowerCase()
                            .includes(params.inputValue.toLowerCase())
                        )
                          return true;
                        if (
                          option.value
                            .toLowerCase()
                            .includes(params.inputValue.toLowerCase())
                        )
                          return true;
                        return false;
                      });

                      const { inputValue } = params;

                      const isValid = Yup.string()
                        .matches(/^(\d{1,2}\.\d{1,2}\.\d{1,2})$/)
                        .isValidSync(inputValue);

                      if (!isValid) return filtered;

                      const isExisting = options.some(
                        (option) =>
                          inputValue ===
                          (typeof option === 'string' ? option : option.value),
                      );

                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          value: inputValue,
                          label: '',
                        });
                      }

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Код ККТУ"
                        variant="standard"
                        error={Boolean(formik.errors.kktuCodes)}
                        helperText={formik.errors.kktuCodes}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
          <ReactRouterPrompt when={isPrompt}>
            {({ isActive, onConfirm, onCancel }) => (
              <Modal
                open={isActive}
                handleClose={onCancel}
                onConfirm={onConfirm}
                title="Подтвердите переход"
                outlinedText="Остаться на этой странице"
                containedText="Покинуть эту страницу"
              >
                Вы действительно хотите покинуть страницу?
              </Modal>
            )}
          </ReactRouterPrompt>
        </Box>
      </Box>
    </Container>
  );
};
