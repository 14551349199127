import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React from 'react';
import { FormControlTypeMap } from '@mui/material/FormControl/FormControl';
import { SelectProps } from '@mui/material/Select/Select';

type OptionsItem<Value extends string | number> = {
  label: string;
  value: Value;
};
export type PSelectMS<Value extends string | number> = {
  variant?: FormControlTypeMap['props']['variant'];
  error?: FormControlTypeMap['props']['error'];
  required?: boolean;
  label: string;
  value?: SelectProps<Value>['value'];
  name?: string;
  onChange?: SelectProps<Value>['onChange'];
  onBlur?: SelectProps<Value>['onBlur'];
  options: OptionsItem<Value>[];
  disabledOption?: (
    value: OptionsItem<Value>['value'],
    label: OptionsItem<Value>['label'],
  ) => boolean;
  helperText?: string;
  disabled?: boolean;
};

export function SelectMS<Value extends string | number>({
  variant = 'standard',
  error,
  required,
  label,
  value,
  name,
  onChange,
  onBlur,
  options,
  disabledOption,
  helperText,
  disabled,
}: PSelectMS<Value>): JSX.Element {
  return (
    <FormControl variant={variant} error={error} disabled={disabled}>
      <InputLabel required={required}>{label}</InputLabel>
      <Select
        required={required}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        sx={{ '.MuiSelect-select.Mui-disabled': { cursor: 'not-allowed' } }}
      >
        {/* eslint-disable-next-line no-shadow */}
        {options.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            disabled={disabledOption?.(value, label)}
          >
            {label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
